<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="6" class="mt-4">
                <h4>{{user.user_plaka ? user.user_plaka : "Plaka Tanımlanmadı!"}}</h4>
            </b-col>
            <b-col class="text-right mt-3 mb-3">
                <date-picker 
                    v-model="range" 
                    type="date" 
                    format="YYYY-MM-DD" 
                    value-type="YYYY-MM-DD"
                    placeholder="Tarih Seçin"
                    range-separator=" / "
                    confirm 
                    range 
                    ref="datePicker"
                    confirm-text='Tamam'></date-picker>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col>
                <b-list-group>
                    <b-list-group-item v-for="firma in user.user_firmalar" :key="firma.firma_id">
                        <b>{{firma.firma_unvan}}</b> - 
                        Gitmesi Gereken: <b>{{firma.gerekli}}</b>,
                        Gidilen: <b>{{firma.gidilen}}</b>,
                        Başarı: <b>{{firma.basari_yuzde}}%</b>
                        </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row> -->
        <b-row>
            <b-col>
                <b-button block class="mt-5" variant="primary" :to="'/KullaniciForm/' + user.user_id">Kullanıcıyı Düzenle</b-button>
            </b-col>
        </b-row>

        <b-row class="mt-5">
            <b-col v-if="alimlar.length > 0">
                <b-table responsive :fields="fields" :items="alimlar"></b-table>
            </b-col>
            <b-col v-else>
                <p>Seçilen tarih aralığında sistemde veri bulunamadı!</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data(){
        return {
            user : [],
            fields : [
                {
                    "key" : "atik_alim_tarih",
                    "label" : "Tarih",
                    "thStyle": { width: '250px'},
                    "sortable": "true"
                },
                {
                    "key" : "firma_unvan",
                    "label" : "Firma",
                    "sortable": "true"
                },
                {
                    "key" : "atik_ad",
                    "label" : "Tur",
                    "thStyle": { width: '200px'},
                    "sortable": "true"
                },
                {
                    "key" : "atik_alim_miktar",
                    "label" : "Miktar",
                    "thStyle": { width: '120px'},
                    "sortable": "true"
                },
            ],
            rangeChange: null,
        }
    },
    mounted(){
        console.log({
            user_id: this.$route.params.id,
            start: this.days.start,
            finish: this.days.finish
        })
        this.$store.dispatch("userFind", {
            user_id: this.$route.params.id,
            start: this.days.start,
            finish: this.days.finish
        }).then(r => {
            if(r){
                this.user = r;
            }else{
                this.$store.commit("initDurum", {
                    status: false,
                    message: "Sistemde kayıtlı kullanıcı bulunamadı!"
                })
                //this.$router.push("/kullanicilar");
            }
        });
    },
    computed: {
        user_sorumlu_oldugu_firmalar(){
            return this.$store.state.user_sorumlu_oldugu_firmalar;
        },
        alimlar(){
            var alimlar = [];
            if(this.user.user_firmalar){
                this.user.user_firmalar.forEach(c => {
                    c.alimlar.forEach(r => {
                        alimlar.push(r)
                    });
                });
            }
            alimlar.sort(this.compare)
            return alimlar;
        },
        days(){
            return this.$store.state.days;
        },
        range: {
            get(){
                return this.rangeChange === null ? [this.days.start, this.days.finish] : this.rangeChange;
            },
            set(val){
                this.rangeChange = val        	
            }
        }
    },

    methods: {
        compare(a, b) {
            const ilkTarih = a.atik_alim_tarih;
            const ikiTarih = b.atik_alim_tarih;

            let comparison = 0;
            if (ilkTarih < ikiTarih) {
                comparison = 1;
            } else if (ilkTarih > ikiTarih) {
                comparison = -1;
            }
            return comparison;
        }
    },

    watch: {
        rangeChange(a){
            var deger = {
                user_id: this.$route.params.id,
                start: a[0],
                finish: a[1],
            }
            this.veriGuncelle(deger);
        }
    }
}
</script>